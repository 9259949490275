import { graphql } from 'gatsby';
import React from 'react';

const BlogImage = ({ module }) => {
  return (
    <>
      <section className={`zigzag lg:py-60 py-30`}>
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div className={`lg:w-12/12 w-full fade-img lg:order-1`}>
              <div className="img-slider ">
                <img src={module.image.mediaItemUrl} alt="" className=""></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogImage;
export const BlogImageFragment = graphql`
  fragment BlogImageFragment on WpPost_Blogcontent_BlogContent_BlogImage {
    # content
    image {
      altText
      mediaItemUrl
      uri
      title
    }
  }
`;
