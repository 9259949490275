import React from "react";
import { graphql, Link } from "gatsby";

const IntroContent = ({ module, postData }) => {
  return (
    <>
      <section className="section-title lg:p-60 py-30">
        <div className="fade-ani px-30">
          <div className="title-black text-center">
          {!module?.hideDate && (
              <span className="text-16 font-400 text-dark uppercase">
                {postData?.date}
              </span>
            )}
            <h1>{module.title}</h1>
            <span className="uppercase text-dark text-11 font-400 mt-10 inline-block font-preheaders">
              {postData?.categories?.nodes[0]?.name}
            </span>
          </div>
          {module.cta && (
            <div className="btn-custom text-center">
              <Link
                to={module.cta.url}
                target={module.cta.target}
                className="link-btn"
              >
                {module.cta.title}
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default IntroContent;

export const IntroContentExperienceFragment = graphql`
  fragment BlogHeroSection on WpPost_Blogcontent_BlogContent_HeroSection {
    # content
    fieldGroupName
    title
    hideDate
    titleCopy
  }
`;
