import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Layout from '../components/layout';
import Seo from '../components/Seo/Seo';
import Blog from '../modules/Blog';
import '../style.css';

const PageTemplate = (props) => {
  const post = props.data.post;
  const { location } = props;

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      document.body.classList.add('scroll-down');
    }
    if (window.pageYOffset <= 100) {
      document.body.classList.remove('scroll-down');
    }
    if (window.pageYOffset > 10) {
      document.body.classList.add('header-sticky');
    }
    if (window.pageYOffset <= 10) {
      document.body.classList.remove('header-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const blogProprty = post.categories.nodes[0].name;
  const propertyName = blogProprty.replace(/-|\s/g, '-').toLowerCase();
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    document.body.classList.add('modal-open');
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  }
  const customStylesPopup = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      width: '100%',
      padding: '0',
    },
  };

  function handleSubmit(evt) {
    evt.preventDefault();
    const [postId, name, email, comment] = evt.target.elements;
    const data = JSON.stringify({
      post: postId.value,
      author_name: name.value,
      author_email: email.value,
      content: comment.value,
    });
    const ACTION_URL = `${process.env.GATSBY_COMMENT_API_URL}`;
    fetch(ACTION_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (response.ok === true) {
          const FormWrapper = document.querySelector('.sucess-message');
          const paragraph = document.createElement('p');
          paragraph.innerText =
            'Your comment has been successfully submitted and is awaiting moderation.';
          FormWrapper.appendChild(paragraph);
          // reset value
          evt.target.reset();
        } else {
          const FormWrapper = document.querySelector('.sucess-message');
          const paragraph = document.createElement('p');
          paragraph.innerText =
            'Sorry, there was an error with submitting your comment. Please try again later.';
          FormWrapper.appendChild(paragraph);
        }
        return response.json();
      })
      .then((object) => {
        console.log('fail');
      })
      .catch((error) => console.error('Error:', error));
  }

  function handleSubmitReply(evt) {
    evt.preventDefault();
    const [postId, commentId, name, email, comment] = evt.target.elements;
    const data = JSON.stringify({
      post: postId.value,
      parent: commentId.value,
      author_name: name.value,
      author_email: email.value,
      content: comment.value,
    });
    const ACTION_URL = `${process.env.GATSBY_COMMENT_API_URL}`;
    fetch(ACTION_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (response.ok === true) {
          const FormWrapper = document.querySelector('.sucess-message-reply');
          const paragraph = document.createElement('p');
          paragraph.innerText =
            'Your comment has been successfully submitted and is awaiting moderation.';
          FormWrapper.appendChild(paragraph);
          // reset value
          evt.target.reset();
        } else {
          const FormWrapper = document.querySelector('.sucess-message-reply');
          const paragraph = document.createElement('p');
          paragraph.innerText =
            'Sorry, there was an error with submitting your comment. Please try again later.';
          FormWrapper.appendChild(paragraph);
        }
        return response.json();
      })
      .then((object) => {
        console.log('fail');
      })
      .catch((error) => console.error('Error:', error));
  }

  const url = post.guid;
  const urlObject = new URL(url);
  const value = urlObject.searchParams.get('p');
  const intValue = parseInt(value);
  const [weburl, setWebUrl] = useState('');
  useEffect(() => {
    setWebUrl(window.location.href);
  });
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = document.getElementById('copyText');
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy.innerText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
  };

  return (
    <Layout props={props} location={location}>
      <Seo seo={post.seo} />
      <div className="blog-main">
        {post?.blogContent && post.blogContent.blogContent !== null ? (
          <div className="blog-main">
            <Blog modules={post.blogContent} postData={post} />
          </div>
        ) : (
          <>
            <div className="blog-main px-45 lgscreen:px-20 bg-white ">
              <div className={`blog-single-content bg-white ${propertyName} `}>
                <div className="lgscreen:px-50 smscreen2:px-0 py-100 lgscreen:py-50 smscreen2:py-30 max-w-[1000px] lgscreen:max-w-full mx-auto">
                  <div className="flex flex-wrap justify-between gap-y-[30px] ipad:flex-col">
                    <div className="left-content">
                      <span className="flex gap-2 text-[#8F9278] text-13 leading-25 font-400 uppercase">
                        {post?.date && <div>{post.date}</div>} <span>|</span>{' '}
                        {blogProprty}
                      </span>
                      <h1 className="mt-5 mb-15 lg:text-37 lg:leading-44 text-22 leading-26 xlscreen:text-33 mdscreen:text-22 mdscreen:leading-26 xlscreen:leading-39 text-black-100">
                        {post.title}
                      </h1>
                      <span className="feature-list text-gold text-13 font-400 leading-24 font-body">
                        {post.blogInterest?.nodes.length > 0 &&
                          post.blogInterest.nodes.map((intrest, j) => (
                            <span key={j}>{intrest.name}</span>
                          ))}
                      </span>
                    </div>
                    <div className="right-content">
                      <div className="flex items-center flex-wrap  gap-4">
                        <button
                          onClick={() => {
                            openModal();
                          }}
                          className="inline-block w-[104px] h-[104px] smscreen2:w-[70px] smscreen2:h-[70px] rounded-999 hover:opacity-70 duration-300 transition-all ease-in-out"
                        >
                          {post?.author?.node.userDetails?.userImage && (
                            <img
                              src={
                                post.author.node.userDetails.userImage
                                  .mediaItemUrl
                              }
                              alt={
                                post.author.node.userDetails.userImage.altText
                              }
                              loading="lazy"
                              width={104}
                              height={104}
                              className="rounded-999 h-full object-cover"
                            />
                          )}
                        </button>
                        {post.author.node.name !== 'nocontributer' && (
                          <div className="author flex flex-col">
                            {post?.author && (
                              <div
                                className="text-dark text-13 leading-25"
                                onClick={() => {
                                  openModal();
                                }}
                              >
                                Written by: {post.author.node.name}
                              </div>
                            )}
                            {post?.author?.node.userDetails
                              ?.userDesignation && (
                              <div className="text-dark text-12 leading-25 uppercase">
                                {post.author.node.userDetails.userDesignation}
                              </div>
                            )}
                            <div
                              className="text-gold text-13 font-400 leading-24 font-body cursor-pointer hover:opacity-60 duration-300 transition-all ease-in-out uppercase underline underline-offset-4 smscreen2:text-11"
                              onClick={() => {
                                openModal();
                              }}
                            >
                              read more about contributor
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="text-[#F0F0F0] h-px -mx-[55px] my-50 lgscreen:mx-0 lgscreen:my-30 smscreen2:my-15" />

                  <div className="blog-single-details content">
                    {post?.content && parse(post.content)}
                  </div>

                  <hr className="text-[#F0F0F0] h-px -mx-[55px] my-50 lgscreen:mx-0 lgscreen:my-30 smscreen2:my-15 mb-[30px]" />

                  <div className="flex flex-wrap gap-3 justify-between mdscreen:justify-center">
                    <a href="/blog/" className="link-btn">
                      Back to all blogs
                    </a>
                    <div className="title-brown flex flex-wrap items-center justify-start mdscreen:justify-center title-line title-right gap-6 smscreen2:pt-10">
                      <h6 className="md:pr-20 text-20 text-dark">
                        Share this blog:
                      </h6>
                      <ul className="flex flex-wrap gap-[10px] bg-tanlight relative z-9 share-list">
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
                              weburl
                            )}`}
                          >
                            <img
                              width={12}
                              height={18}
                              src="../../images/FaceBook-ico.png"
                              alt="Facebook"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                              weburl
                            )}`}
                          >
                            <img
                              width={21}
                              height={21}
                              src="../../images/Twitter.png"
                              alt="Twitter"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                              weburl
                            )}`}
                          >
                            <img
                              width={19}
                              height={19}
                              src="../../images/Whatsapp.png"
                              alt="Whatsapp"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                              weburl
                            )}`}
                          >
                            <img
                              width={21}
                              height={21}
                              src="../../images/LinkedIn.png"
                              alt="LinkedIn"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`mailto:?subject=${encodeURIComponent(
                              post.title
                            )}&body=${encodeURIComponent(weburl)}`}
                          >
                            <img
                              width={21}
                              height={21}
                              src="../../images/Email.png"
                              alt="Email"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={copyToClipboard}
                          >
                            <img
                              width={21}
                              height={21}
                              src="../../images/Link-ico.png"
                              alt="Link"
                            />
                            <p
                              id="copyText"
                              onClick={copyToClipboard}
                              className="hidden"
                            >
                              {weburl}
                            </p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="comment-form bg-gray-600 -mx-[55px] my-50 lgscreen:mx-0 lgscreen:my-30 px-[55px] py-40 lgscreen:px-30 lgscreen:py-20">
                    <h4>Leave a comment</h4>
                    <div className="flex flex-wrap">
                      <div className="w-10/12 lgscreen:w-full">
                        <form
                          className="mt-30"
                          onSubmit={handleSubmit.bind(this)}
                        >
                          <input type="hidden" id="postId" value={intValue} />
                          <div className="grid grid-cols-2 lgscreen:grid-cols-1 gap-x-[80px] gap-y-[60px] lgscreen:gap-y-[30px] mb-40">
                            <div className="form-group flex flex-wrap">
                              <input
                                id="name"
                                type="text"
                                required
                                placeholder="First Name*"
                              />
                            </div>
                            <div className="form-group flex flex-wrap">
                              <input
                                id="email"
                                type="email"
                                required
                                placeholder="Email*"
                              />
                            </div>
                            <div className="lg:col-span-2 form-group flex flex-wrap">
                              <textarea
                                id="comment"
                                rows={4}
                                required
                                placeholder="Comment*"
                              />
                            </div>
                          </div>
                          <input
                            type="submit"
                            value="Post comment"
                            className="button button-gray cursor-pointer"
                          />
                        </form>
                        <div className="sucess-message"></div>
                      </div>
                    </div>
                  </div>

                  <div className="comment-display">
                    {post?.comments?.nodes.length > 0 && <h4>Comments</h4>}
                    {post?.comments?.nodes.map((item, i) => (
                      <div className="flex flex-col mb-50">
                        <div className="flex gap-3 items-start pb-25 border-b-1 border-solid border-[#F0F0F0]">
                          <img
                            src="../../images/User-comment.svg"
                            width={44}
                            height={44}
                            className="rounded-999"
                            alt=""
                          />
                          <div className="flex flex-col gap-1">
                            <span className="text-20 text-black-200 font-400 leading-44 capitalize">
                              {item.author.node.name}
                            </span>
                            <p>{parse(item.content)}</p>
                            <span className="text-gold text-14 font-400 leading-25 cursor-pointer">
                              Reply
                            </span>
                          </div>
                        </div>
                        <div className="pl-120 lgscreen:pl-60 smscreen2:pl-30 my-25">
                          <span className="text-black-200 text-18 font-400 leading-44">
                            Reply to {item.author.node.name}
                          </span>
                          <form
                            className="mt-30"
                            onSubmit={handleSubmitReply.bind(this)}
                          >
                            <input type="hidden" id="postId" value={intValue} />
                            <input
                              type="hidden"
                              id="commentId"
                              value={item.databaseId}
                            />
                            <div className="grid grid-cols-2 lgscreen:grid-cols-1 gap-x-[80px] gap-y-[60px] lgscreen:gap-y-[30px] mb-40">
                              <div className="form-group flex flex-wrap">
                                <input
                                  id="name"
                                  type="text"
                                  required
                                  placeholder="Name*"
                                />
                              </div>
                              <div className="form-group flex flex-wrap">
                                <input
                                  id="email"
                                  type="email"
                                  required
                                  placeholder="Email*"
                                />
                              </div>
                              <div className="lg:col-span-2 form-group flex flex-wrap">
                                <textarea
                                  id="comment"
                                  rows={4}
                                  required
                                  placeholder="Comment*"
                                />
                              </div>
                            </div>
                            <div className="flex gap-5 items-center">
                              <input
                                type="submit"
                                value="Post comment"
                                className="button button-gray cursor-pointer"
                              />
                              <span className="text-gold text-14 font-400 leading-25 cursor-pointer">
                                Cancel Reply
                              </span>
                            </div>
                          </form>
                          <div className="sucess-message-reply"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* related cards start */}
              {post?.blogPostContent?.selectRelatedStories && (
                <div
                  className={`img-grid portrait lg:py-60 py-30 blogLoop-slider`}
                >
                  <div className="container-fluid">
                    <h4 className="mb-[45px] lgscreen:mb-20 font-heading text-[30px] font-400 leading-[32px]">
                      {post.blogPostContent.relatedStoryHeading}
                    </h4>
                    <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
                      {post.blogPostContent.selectRelatedStories.map(
                        (item, i) => (
                          <div
                            className={`lg:w-4/12 w-full lg:px-20 fade-img`}
                            key={i}
                          >
                            <Link to={item?.uri}>
                              <img
                                src={item?.featuredImage?.node.imgixImage.url}
                                alt={item?.featuredImage?.node?.altText || ''}
                                className={`h-[300px] object-cover w-full`}
                                loading="lazy"
                              />
                            </Link>
                            <div className="img-content pt-30 max-w-800">
                              <span className="uppercase text-dark text-13 font-400 leading-24 font-body">
                                {item?.date} | {item?.author.node.name}
                              </span>
                              <Link to={item?.uri}>
                                <h4 className="text-black-200 mt-10 mb-20 font-heading text-[28px] font-400 leading-[32px]">
                                  {item?.title}
                                </h4>
                              </Link>
                              <span className="feature-list text-gold text-13 font-400 leading-24 font-body">
                                {item.blogInterest?.nodes?.length > 0 &&
                                  item.blogInterest.nodes.map((intrest, j) => (
                                    <span key={j}>{intrest.name}</span>
                                  ))}
                              </span>
                              <div className="content text-sm mt-15 mb-30">
                                {item?.excerpt && parse(item?.excerpt)}
                              </div>

                              <Link to={item?.uri} className="link-btn">
                                Explore
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* related cards end */}
            </div>

            <div className="author-modal">
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStylesPopup}
              >
                <div className="img-modal img-modal-popup guides-popup !w-full">
                  <div className="flex flex-wrap  bg-white shadow-card relative h-full">
                    <button
                      onClick={closeModal}
                      className="absolute right-20 top-20 mdscreen:top-50 w-50 h-50 bg-white bg-opacity-80 rounded-999 inline-block text-center z-9"
                    >
                      <img
                        src="../../../images/modal-close.png"
                        className="m-auto max-w-20"
                        alt=""
                        width={14}
                        height={12}
                      />
                    </button>

                    <div className="lg:w-6/12 zigzag w-full smscreen2:h-fit">
                      {post?.author?.node.userDetails?.userImage && (
                        <div className="img landscape">
                          <img
                            src={
                              post.author.node.userDetails.userImage
                                .mediaItemUrl
                            }
                            alt={post.author.node.userDetails.userImage.altText}
                            loading="lazy"
                            className="mdscreen:pt-80 mdscreen:bg-[#f9f9f9]"
                            width={702}
                            height={757}
                          />
                        </div>
                      )}
                    </div>
                    <div className="lg:w-6/12 zigzag-content w-full lg:h-full">
                      <div className="px-120 lgscreen:px-60 smscreen2:px-30 py-100 lgscreen:py-50 smscreen2:py-30 flex flex-col justify-center h-full">
                        {post?.author && (
                          <h4 className="mb-10">{post.author.node.name}</h4>
                        )}
                        {post?.author?.node.userDetails?.userDesignation && (
                          <span className="text-16 font-400 leading-25 uppercase mb-15">
                            {post.author.node.userDetails.userDesignation}
                          </span>
                        )}
                        {post?.author?.node.name !== 'nocontributer' && (
                          <p className="text-16 leading-24">
                            {parse(post.author.node?.description)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query PostById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      content
      guid
      date(formatString: "DD MMMM, YYYY")
      categories {
        nodes {
          name
        }
      }
      blogInterest {
        nodes {
          name
        }
      }
      blogPostContent {
        relatedStoryHeading
        selectRelatedStories {
          ... on WpPost {
            id
            date(formatString: "DD MMMM YYYY")
            featuredImage {
              node {
                altText
                imgixImage {
                  url(imgixParams: { w: 1340, h: 540, q: 95 })
                }
              }
            }
            title
            blogInterest {
              nodes {
                slug
                name
              }
            }
            categories {
              nodes {
                slug
                name
              }
            }
            excerpt
            link
            guid
            uri
            author {
              node {
                name
              }
            }
          }
        }
      }
      author {
        node {
          name
          description
          userDetails {
            userDesignation
            userImage {
              altText
              mediaItemUrl
            }
          }
        }
      }
      comments {
        nodes {
          author {
            node {
              name
            }
          }
          content
          databaseId
        }
      }
      seo {
        fullHead
        metaDesc
        canonical
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...BlogModulesFragment
    }
  }
`;
