import React from "react";
import parse from "html-react-parser";
import { graphql } from "gatsby";

const FullWidthContent = ({ module }) => {
  return (
    <>
      <section className="single-blog ">
        <div className="container-fluid px-20">
          <div className=" px-0 bg-white lg:py-60 py-30">
            <div className="container m-auto">
              <div className="content fade-ani px-30">
                {module.content && parse(module.content)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullWidthContent;

export const FullWidthContentExperienceFragment = graphql`
  fragment BlogFullWidthContent on WpPost_Blogcontent_BlogContent_FullWidthContent {
    # content
    content
    fieldGroupName
  }
`;
