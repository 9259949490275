import React, { useState, useEffect } from "react";

const ShareBlog = () => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(window.location.href);
  });
  return (
    <>
      <section className="blog-share single-blog lg:mb-120 mb-60">
        <div className="container-fluid px-20">
          <div className="bg-white lg:pb-120 pb-60 lg:pt-60 pt-30">
            <div className="container px-30 m-auto">
              <div className="title-brown flex flex-wrap items-center justify-start title-line title-right">
                <h3 className="pr-30 text-24 text-dark">Share this blog:</h3>
                <ul className="flex flex-wrap space-x-4  bg-tanlight relative z-9 xsscreen3:pt-10">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://t.me/share/url?url=${encodeURIComponent(url)}`}
                  >
                    <img src="../../images/Telegram-blog.png" alt="Telegram" />
                  </a>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
                        url
                      )}`}
                    >
                      <img src="../../images/Facebook-blog.png" alt="Facebook" />
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        url
                      )}`}
                    >
                      <img src="../../images/Twitter-blog.png" alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="#"
                    >
                      <img src="../../images/Google-alt-blog.png" alt="LinkedIn" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShareBlog;
